<template>
  <input
    :class="{ hide: isHidden }"
    :id="id"
    :name="name"
    :multiple="isMultiple"
    @change="compressImage"
    type="file"
    accept="image/x-png,image/jpeg"
  />
</template>

<script>
import Compressor from './compressor.esm';

export default {
  name: 'VueCompressor',
  props: {
    id: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    async compressImage(event) {
      if (!this.isMultiple) {
        const compressedBlob = await this.compressPromise(
          event.target.files[0]
        );
        const compressedFile = this.blobToFile(
          compressedBlob,
          event.target.files[0].name
        );
        this.$emit('on-compressed', compressedFile);
      }
      if (this.isMultiple) {
        const compressedBlobList = await Promise.all(
          Array.from(event.target.files).map((file) => {
            return this.compressPromise(file);
          })
        );
        const compressedFileList = Array.from(compressedBlobList).map(
          (file) => {
            return this.blobToFile(file, file.name);
          }
        );

        this.$emit('on-compressed', compressedFileList);
      }
    },
    compressPromise(file, quality = 0.8) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: quality,
          success: resolve,
          error: reject,
        });
      });
    },
    blobToFile(blob, fileName) {
      return new File([blob], encodeURI(fileName), {
        type: blob.type,
        lastModified: new Date(),
      });
    },
    blobToBase64Promise(blob) {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = function () {
            resolve(reader.result);
          };
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
